<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex flex-1 overflow-auto">
      <ul
        class="w-4/12 overflow-auto p-8 bg-blue-100 text-black mr-12"
        aria-label="notes"
      >
        <li>
          because they carousel through at a decent pace, more pictures is
          better. we recommend you add photos for every conversation topic and
          level of detail that you can.
        </li>
        <li>
          you don’t need a professional photographer. if you have somebody with
          a decent eye for photos (i.e they know the rule of thirds), even
          mobile phone photos can look great on the web.
        </li>
        <li>
          keep your photo captions short. no more than two lines long.
          otherwise, it will not be as aesthetically pleasing on the screen.
        </li>
      </ul>
      <div class="overflow-auto w-full">
        <div class="form-row">
          <div class="form-col">
            <label for="categories">main topic</label>
            <text-input
              :value="this.category.intent_category_name"
              disabled
            />
          </div>
          <div class="form-col">
            <label for="categories">conversation detail</label>
            <text-input
              :value="this.intent.suggestion_text"
              disabled
            />
          </div>
          <div class="form-col">
            <label for="categories">next level detail</label>
            <text-input
              :value="this.subIntent.suggestion_text"
              disabled
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-col">
            <div id="upload_photo" class="w-full max-w-3xl mt-5 h-full m-auto">
              <img class="w-full h-auto mr-2" :src="editImageUrl" />
              <div class="mt-2">
                <file-manager
                  :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                  :libraryFilters="['marketing', 'cms']"
                  :main-directory="'dh'"
                  :max-upload-size="20"
                  :max-nb-of-files="1"
                  :cropper-options="{aspectRatio: 16 / 9, cropBoxResizable: true, zoomOnWheel: false}"
                  v-model="imagestoUpload"
                >
                 <template v-slot:trigger="{ openFileManager, canAddFiles }">
                   <a
                    class="mt-2 underline cursor-pointer change-image-btn"
                    @click="openFileManager"
                    v-if="canAddFiles">change image</a>
                 </template>
                </file-manager>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mb-5">
          <div class="form-col">
            <label for="contentText">photo description (required)</label>
            <text-input
              class="h-full mb-5"
              id="contentText"
              v-model="descriptionImg"
              multiline
            />
            <span v-if="hasError('contentText')" class="form-error">
              {{ showError('contentText') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <modal-footer
      :footer="footer"
      :primary="onSave"
      :tertiary="handleRedirect"
    ></modal-footer>
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import NotifyMixin from '@/mixins/NotifyMixin'
import Loader from '@/components/ui/Loader'
import PhotosMixin from '@/components/digital_human/PhotosMixin'
import {mapGetters, mapActions} from "vuex";
import ModalFooter from '@/components/ui/modals/ModalFooter'
import FileManager from "@/components/ui/filemanager/FileManager";
import TextInput from '@/components/ui/TextInput';

export default {
  name: 'CreateResponse',
  components: { Loader, ModalFooter, FileManager, TextInput },
  mixins: [AuthMixin, ModalNavigation, NotifyMixin, PhotosMixin],
  data() {
    return {
      category: {},
      intent: {},
      subIntent: {},
      descriptionImg: '',
      images64: [],
      imagestoUpload: [],
      managerId: null,
      temporaryImgUrl: '',
      manager: {},
      footer: {
        primaryButton: 'save changes',
        tertiaryButton: 'cancel',
      },
    }
  },
  computed: {
    ...mapGetters({
        getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
        getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
    }),
  },
  methods: {
    ...mapActions({
        setIdImageToEdit: 'photos_uploader/setIdImageToEdit',
        setIsFromEditPhoto: 'photos_uploader/setIsFromEditPhoto',
    }),
    canAddFiles() {
      if(!this.manager)
          return true;

      if(!this.manager.selectedFiles)
          return true;

      return  this.manager.maxSelection > this.manager.selectedFiles.length;
    },
    handleOpenUploaderModal() {
      this.setIdImageToEdit(this.$route.params.id);
      this.setIsFromEditPhoto(true);
      this.$router.push( {name: 'conversations_responses.create-card'}).then( () => {});
    },
    handleRedirect() {
      this.redirect('conversations_responses.index')
    },
    fetchCard() {
      this.setLoading(true)
      this.$dhDataProvider
        .getOne('card', { id: this.$route.params.id })
        .then((res) => {
          this.category = { intent_category_name: res.category_name }
          if (res.content_text && res.content_text !== '')
            this.setImageDescription(res.content_text)
          this.descriptionImg = this.imageDescription
          this.temporaryImgUrl = res.image_url;
          this.setEditImageUrl(
            res.image_url ||
              'https://dwlp4hez71ksk.cloudfront.net/s3/OeAAhL0XeAIhXtlbu9RDf9jx9vuLmiqNzwIbcqIm.jpeg-large'
          )
          this.intent = {
            intent_name: res.intent_name,
            suggestion_text: res.suggestion_text,
          }
          this.subIntent = {
            intent_name: res.next_level,
            suggestion_text: res.next_level_suggestion_text,
          }
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    onSave() {
      if (!this.community) {
        this.notifyError(
          'please select a community to continue, then refresh the browser'
        )
        return
      }
      if (this.validator.passes()) {
        if (this.imageDescription === '') {
          this.descriptionImg = this.imageDescription
        } else {
          this.setImageDescription(this.descriptionImg)
        }
        if (!this.editImageUrl) return;
        this.setLoading(true);
        this.$dhDataProvider
          .update('card', {
            id: this.$route.params.id,
            data: {
              id_community: this.community?.id,
              intent_name:
                this.subIntent?.intent_name !== 'general' && this.subIntent
                  ? this.subIntent?.intent_name
                  : this.intent?.intent_name,
              content_text: this.descriptionImg,
              image_url: this.editImageUrl,
            },
          })
          .then(() => {
            this.notifySuccess('The photo was updated successfully')
            this.$router.push({ name: 'conversations_responses.index' })
          })
          .catch((error) => this.notifyError(error.message))
          .finally(() => this.setLoading(false))
      }
    },
  },
  watch: {
    imagestoUpload: {
      handler: function () {
        if (this.imagestoUpload.length > 0) {
          const fileUrl = this.getThumbnailUrlByFileId(this.imagestoUpload[0])
          this.setEditImageUrl(fileUrl);
        } else this.setEditImageUrl(this.temporaryImgUrl);
      },
      deep: true,
      immediate: true
    }
  },
  validations: {
    descriptionImg: 'required',
  },
  created() {
    this.initValidator()
    this.fetchCard()
    if (this.$route.params.id) this.setIdImageToEdit(this.$route.params.id)
    if (this.imageDescription !== '')
      this.descriptionImg = this.imageDescription
  },
}
</script>

<style scoped>
.form {
  width: 55%;
}
ul:before {
  content: attr(aria-label);
  font-size: 1rem;
  font-weight: bold;
  color: black;
}
ul > li {
  @apply py-3 font-light text-black;
}
.change-image-btn {
  color: var(--highlightColor500);
}
</style>
